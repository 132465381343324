
import Vue from 'vue'
import { getStop } from '@/utils/localstore/stops'
import LineIcon from '@/components/LineIcon.vue'
import UiHr from '@/components/ui/UiHr.vue'

export default Vue.extend({
  name: 'AppNav',
  components: { UiHr, LineIcon },
  data: () => ({
    showTabsList: false,
  }),
  methods: {
    getStop,
    tabIndex () {
      return Number(this.$route.params.tab) - 1 as number
    },
    addTab () {
      this.$store.commit('addTab')
      this.$router.push(`/timetables/${this.$store.state.tabs.length}`)
    },
    closeTab (tabIndex: number) {
      const currentTabIndex = Number(this.$route.params.tab) - 1
      if (currentTabIndex > tabIndex) {
        const { line, stop } = this.$store.state.tabs[currentTabIndex]
        this.$store.commit('closeTab', { tabIndex })
        this.$router.push(`/timetables/${currentTabIndex}${line ? `/${line}` : ''}${stop ? `/${stop}` : ''}`)
      } else {
        this.$store.commit('closeTab', { tabIndex })
        const { line, stop } = this.$store.state.tabs[currentTabIndex]
        this.$router.push(`/timetables/${currentTabIndex + 1}${line ? `/${line}` : ''}${stop ? `/${stop}` : ''}`)
      }
    },
  },
})


import Vue from 'vue'
import AppNav from '@/components/AppNav.vue'
import AppHeader from '@/components/AppHeader.vue'

export default Vue.extend({
  name: 'AppView',
  components: {
    AppHeader,
    AppNav,
  },
})

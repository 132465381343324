
import Vue, { PropType } from 'vue'

export type PrevNextItem = {
  label: string,
  link: string,
}

export default Vue.extend({
  name: 'UiPrevNext',
  props: {
    prevItems: [] as PropType<PrevNextItem[]>,
    nextItems: [] as PropType<PrevNextItem[]>,
  },
})


import Vue, { PropType } from 'vue'
import { StopType } from '@/utils/parser'
import { getStop } from '@/utils/localstore/stops'
import UiPrevNext from '@/components/ui/UiPrevNext.vue'
import UiHr from '@/components/ui/UiHr.vue'

export default Vue.extend({
  name: 'StopPrevNextStops',
  components: {
    UiHr,
    UiPrevNext,
  },
  data: () => ({
    isOpen: false,
  }),
  props: {
    stop: Object as PropType<StopType>,
  },
  methods: {
    getStop,
  },
})

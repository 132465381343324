
import Vue, { PropType } from 'vue'
import UiSchedulesDetailsTimeWrapped from '@/components/ui/Schedules/UiSchedulesDetailsTimeWrapped.vue'
import UiSchedulesDetailsTimeInline from '@/components/ui/Schedules/UiSchedulesDetailsTimeInline.vue'
import { VisitType } from '@/utils/fetcher'
import { toTime } from '@/utils/time'

export default Vue.extend({
  name: 'StopSchedulesDetailsTimes',
  components: {
    UiSchedulesDetailsTimeWrapped,
    UiSchedulesDetailsTimeInline,
  },
  props: {
    visit: Object as PropType<VisitType>,
    isInline: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toTime,
  },
})

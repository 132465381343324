var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"stop-prev-next"},[_c('UiHr'),_c('div',{staticClass:"content"},[_c('button',{staticClass:"hint",attrs:{"type":"button"},on:{"click":() => _vm.isOpen = !_vm.isOpen}},[_vm._v(" "+_vm._s(`— ${_vm.isOpen ? 'Cacher' : 'Afficher'} les arrêts adjacents —`)+" ")]),(_vm.isOpen)?_c('UiPrevNext',{attrs:{"prev-items":_vm.stop.prevStops.slice().reverse().map(prevStop => ({
        label: _vm.getStop(_vm.$route.params.line, prevStop).displayName,
        link: `/timetables/${_vm.$route.params.tab}/${_vm.$route.params.line}/${prevStop}`,
      })),"next-items":_vm.stop.nextStops.slice().reverse().map(nextStop => ({
        label: _vm.getStop(_vm.$route.params.line, nextStop).displayName,
        link: `/timetables/${_vm.$route.params.tab}/${_vm.$route.params.line}/${nextStop}`,
      }))}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
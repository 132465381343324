
import StopSchedulesDetailsTimes from '@/components/Stop/StopSchedulesDetailsTimes.vue'
import StopSchedulesDetailsTimesNonStopPassage from '@/components/Stop/StopSchedulesDetailsTimesNonStopPassage.vue'
import StopSchedulesVisitTime from '@/components/Stop/StopSchedulesVisitTime.vue'
import UiSchedulesDetailsRow from '@/components/ui/Schedules/UiSchedulesDetailsRow.vue'
import { PropType } from 'vue'
import { VisitType } from '@/utils/fetcher'

export default {
  name: 'StopSchedulesVisit',
  components: {
    StopSchedulesDetailsTimes,
    StopSchedulesDetailsTimesNonStopPassage,
    StopSchedulesVisitTime,
    UiSchedulesDetailsRow,
  },
  props: {
    updateCounter: Number,
    visit: {
      type: Object as PropType<VisitType>,
      required: true,
    },
  },
  computed: {
    variant () {
      if (!this.visit) {
        return 'normal'
      }
      if (this.visit.departureStatus === 'cancelled' || this.visit.arrivalStatus === 'cancelled') {
        return 'alert'
      }
      if (this.visit.nonStopPassage || !this.visit.departureTime) {
        return 'shaded'
      } else {
        return 'normal'
      }
    },
  },
}


import Vue from 'vue'
import { synchronize } from '@/utils/localstore/synchronizer'

export default Vue.extend({
  name: 'SettingsPage',
  data: () => ({
    updatedAt: new Date(localStorage.getItem('lines.updatedAt') as string),
  }),
  methods: {
    synchronize () {
      synchronize().then(() => {
        this.updatedAt = new Date(localStorage.getItem('lines.updatedAt') as string)
      })
    },
  },
})


import Vue, { PropType } from 'vue'
import StopSchedulesVisits from '@/components/Stop/StopSchedulesVisits.vue'
import { StopType } from '@/utils/parser'
import { fetchTimetables, VisitType } from '@/utils/fetcher'
import { getLinesByRef } from '@/utils/localstore/lines'

export default Vue.extend({
  name: 'StopSchedules',
  components: {
    StopSchedulesVisits,
  },
  props: {
    stop: Object as PropType<StopType>,
  },
  data: () => ({
    fetchAbortController: new AbortController(),
    visitsByDirections: [] as [string, VisitType[]][],
    debugData: new Set(),
    updatedAt: Object as Date,
    updateCounter: 0,
    updateInterval: 0,
  }),
  computed: {
    linesByRef () {
      return getLinesByRef()
    },
  },
  watch: {
    stop () {
      this.visitsByDirections = []
      this.fetchAbortController.abort()
      this.fetchAbortController = new AbortController()
      this.update()
    },
  },
  created () {
    this.update()
    this.updateInterval = setInterval(() => {
      ++this.updateCounter
    }, 1000)
  },
  destroyed () {
    clearInterval(this.updateInterval)
  },
  methods: {
    update () {
      this.debugData = new Set()
      this.fetch()
    },
    fetch () {
      fetchTimetables(this.stop.monitoringRefs, this.fetchAbortController.signal, [this.$route.params.line])
        .then(({ visits, debugData, updatedAt }: any) => {
          this.visitsByDirections = visits
          this.debugData = debugData
          this.updatedAt = updatedAt
        })
    },
  },
})


import Vue, { PropType } from 'vue'
import { StopType } from '@/utils/parser'
import LineIcon from '@/components/LineIcon.vue'
import DesignPageTitle from '@/components/ui/UiPageTitle.vue'

export default Vue.extend({
  name: 'StopHeader',
  components: {
    LineIcon,
    DesignPageTitle,
  },
  props: {
    stop: Object as PropType<StopType>,
  },
})


import Vue, { PropType } from 'vue'
import UiSchedulesDetailsTimeWrapped from '@/components/ui/Schedules/UiSchedulesDetailsTimeWrapped.vue'
import { VisitType } from '@/utils/fetcher'
import { toTime } from '@/utils/time'

export default Vue.extend({
  name: 'StopSchedulesDetailsTimesNonStopPassage',
  components: {
    UiSchedulesDetailsTimeWrapped,
  },
  props: {
    visit: Object as PropType<VisitType>,
  },
  methods: {
    toTime,
  },
})

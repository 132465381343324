
import Vue from 'vue'

export default Vue.extend({
  name: 'LineMapDrawingCell',
  props: {
    content: String,
    isLargeCircle: {
      default: false,
      type: Boolean,
    },
  },
})

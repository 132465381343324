
import Vue, { PropType } from 'vue'

export type UiIconSize = 'sm' | 'md' | 'lg'

export default Vue.extend({
  name: 'UiIcon',
  props: {
    src: String,
    alt: String,
    size: String as PropType<UiIconSize>,
    fadeIn: {
      type: Boolean,
      default: false,
    },
  },
})


import { Time } from '@/utils/time'
import Vue, { PropType } from 'vue'
export default Vue.extend({
  name: 'UiClock',
  props: {
    time: {} as PropType<Time>,
    textGray: Boolean,
  },
})

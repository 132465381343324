
import Vue, { PropType } from 'vue'
import { VisitType } from '@/utils/fetcher'
import StopSchedulesVisit from '@/components/Stop/StopSchedulesVisit.vue'

export default Vue.extend({
  name: 'StopSchedulesVisits',
  components: {
    StopSchedulesVisit,
  },
  props: {
    visits: {
      type: Array as PropType<VisitType[]>,
    },
  },
  data: () => ({
    updateCounter: 0,
    updateInterval: 0,
  }),
  created () {
    this.updateInterval = setInterval(() => {
      ++this.updateCounter
    }, 5000)
  },
  destroyed () {
    clearInterval(this.updateInterval)
  },
})

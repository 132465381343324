

import { PropType } from 'vue'

export type UiSchedulesTimeVariant = 'normal' | 'alert' | 'shaded'

export default {
  name: 'UiSchedulesTime',
  props: {
    variant: String as PropType<UiSchedulesTimeVariant>,
    time: String,
  },
}


import Vue, { PropType } from 'vue'
import UiClock from '@/components/ui/UiClock.vue'
import { Time, toTime } from '@/utils/time'

export default Vue.extend({
  name: 'UiSchedulesDetailsTimeWrapped',
  components: {
    UiClock,
  },
  props: {
    label: String,
    time: Object as PropType<Time>,
    isTimeTextGray: {
      type: Boolean,
      default: false,
    },
    sublabel: String,
  },
  methods: {
    toTime,
  },
})

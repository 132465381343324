
import Vue, { PropType } from 'vue'
import { RouteMapType } from '@/utils/parser'
import DrawingCell from './LineMapDrawingCell.vue'
import LabelCell from './LineMapLabelCell.vue'

export default Vue.extend({
  name: 'LineMapTable',
  components: { DrawingCell, LabelCell },
  props: {
    line: String,
    routeMap: [] as PropType<RouteMapType>,
  },
  methods: {
    shouldBeFilledCell (cell: string) {
      return [
        '*', '|', '↑', '↓', '^', 'v',
        'o\\', 'o/', '\\o', '/o', '\\.', '/.', '.\\', './',
      ].includes(cell)
    },
  },
})


import Vue, { PropType } from 'vue'
import DesignIcon, { UiIconSize } from '@/components/ui/UiIcon.vue'

export type LineIconColor = 'colors' | 'light' | 'dark'

export default Vue.extend({
  name: 'LineIcon',
  components: {
    DesignIcon,
  },
  props: {
    lineSlugName: String,
    fadeIn: {
      type: Boolean,
      default: false,
    },
    color: String as PropType<LineIconColor>,
    size: String as PropType<UiIconSize>,
  },
})

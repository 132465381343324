
import Vue from 'vue'

export default Vue.extend({
  name: 'UiSchedulesDetailsRow',
  props: {
    label: String,
    value: String,
  },
})


import Vue from 'vue'
import LineIcon from '@/components/LineIcon.vue'
import UiClock from '@/components/ui/UiClock.vue'
import { Time } from '@/utils/time'
export default Vue.extend({
  name: 'AppHeader',
  components: {
    LineIcon,
    UiClock,
  },
  data: () => ({
    clockInterval: 0,
    time: Object as Time,
  }),
  created () {
    this.clockInterval = setInterval(() => {
      const t = new Date()
      this.time = {
        hh: t.getHours().toString(),
        mm: t.getMinutes().toString().padStart(2, '0'),
        ss: t.getSeconds().toString().padStart(2, '0'),
      }
    }, 500)
  },
  destroyed () {
    clearInterval(this.clockInterval)
  },
})

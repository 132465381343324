
import Vue, { PropType } from 'vue'
import { LineConnectionType } from '@/utils/parser'
import LineIcon from '@/components/LineIcon.vue'

export default Vue.extend({
  name: 'LineMapLabelCell',
  components: { LineIcon },
  props: {
    stopName: String,
    href: String,
    lineConnections: [] as PropType<LineConnectionType[]>,
  },
})
